import React from "react"
import { Link, graphql } from "gatsby"
import Metadata from "../components/Metadata/Metadata"
import LayoutWork from "../layout/LayoutWeblog"
import Heading from "../components/Heading/Heading"
import Filter from "../components/Filter/FilterWeblog"
import styles from "../components/WeblogList/WeblogList.module.scss"
import {
    pagePathBuilder,
    frontmatterPathBuilder,
} from "../helpers/pathBuilders"

export const pageQuery = graphql`
    query($tag: String) {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {
                fields: { sourceInstanceName: { eq: "weblog-single" } }
                frontmatter: { tags: { in: [$tag] } }
            }
        ) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        pathDirectory
                        pathName
                        title
                        date(formatString: "DD MMMM, YYYY")
                    }
                    excerpt
                }
            }
        }
    }
`

const Tags = ({ pageContext, data }) => {
    const { tag } = pageContext
    const WeblogList = () => (
        <ul className={styles.weblog_list}>
            {data.allMarkdownRemark.edges.map(({ node }) => (
                <li className={styles.weblog_list_item} key={node.id}>
                    <h2>
                        <Link
                            className={styles.weblog_list_link}
                            to={pagePathBuilder(node)}
                        >
                            {frontmatterPathBuilder(node).title}
                        </Link>
                    </h2>
                    <Link
                        className={styles.weblog_list_date}
                        to={pagePathBuilder(node)}
                    >
                        {frontmatterPathBuilder(node).date}
                    </Link>
                    <p className={styles.weblog_list_excerpt}>
                        {node.excerpt}{" "}
                        <Link
                            className={styles.weblog_list_readmore}
                            to={pagePathBuilder(node)}
                        >
                            (Read more)
                        </Link>
                    </p>
                </li>
            ))}
        </ul>
    )

    return (
        <>
            <Metadata title={`Weblog Posts Filtered by "${tag}"`} />
            <LayoutWork
                heading={
                    <Heading
                        headingLevel="1"
                        headingTitle={`Weblog Posts, Filtered by ${tag}`}
                    />
                }
                filter={<Filter resetFilter={true} />}
                webloglist={<WeblogList />}
            />
        </>
    )
}

export default Tags
